<template>

  <div class="pa-importer-body">
    <h3>2. Choose Importer</h3>

    <select v-model="post_type" :disabled="busy" class="pa-importer-options" @change="onChange">

      <option value="none">No option</option>

      <optgroup v-if="post_types.length > 0" label="Post Types">
        <option v-for="(post_type, index) in post_types" :key="index" :value="post_type.slug"
                data-type="post_type">{{ post_type.name.charAt(0).toUpperCase() + post_type.name.slice(1) }}
        </option>
      </optgroup>

      <optgroup v-if="taxonomies.length > 0" label="Taxonomies">
        <option v-for="(taxonomy, index) in taxonomies" :key="index" :value="taxonomy.slug" data-type="taxonomy">
          {{ taxonomy.name.charAt(0).toUpperCase() + taxonomy.name.slice(1) }}
        </option>
      </optgroup>

    </select>

    <select v-if="data_type !== 'none' && post_type === 'product'" v-model="product_option" :disabled="busy" @change="isReady"
            class="pa-importer-options">
      <option value="none">Choose Product Type</option>
      <option value="single">Single / Variable</option>
      <option value="bundles">Bundles</option>
    </select>

    <select v-if="data_type !== 'none' && post_type !== 'none'" v-model="merge" @change="isReady"
            :disabled="busy" class="pa-importer-options">
      <option value="none">Choose Import Type</option>
      <option value="regular">Regular Import</option>
      <option value="merge">Merge Import</option>
    </select>

    <select v-if="data_type !== 'none' && post_type === 'product' && merge === 'merge'" v-model="mergeKey" @change="isReady"
            :disabled="busy"
            class="pa-importer-options">
      <option value="none">Merge Key</option>
      <option value="post_id">Post ID</option>
      <option value="pmeta:_sku">Sku</option>
    </select>

    <select v-if="data_type !== 'none' && post_type !== 'none' && post_type !== 'product' && merge === 'merge'" v-model="mergeKey" @change="isReady"
            :disabled="busy"
            class="pa-importer-options">
      <option value="none">Merge Key</option>
      <option value="post_id">Post ID</option>
    </select>

    <button v-if="merge !== 'none'" :disabled="busy" class="button button-secondary mt-0 mb-2" type="button"
            @click="showOptions = !showOptions">
      Toggle Options
    </button>

    <div v-if="showOptions" class="mb-2">
      <h3 class="mt-0">3. Limit</h3>
      <p>Choose the maximum records to import. 0 - Unlimited</p>
      <input v-if="post_type !== 'none'" v-model="records_limit" :disabled="busy" placeholder="max # of records" @change="isReady"
             type="number"/>
    </div>

    <div v-if="showOptions" class="mb-2">
      <h3 class="mt-0">4. AS400</h3>
      <p>Is this CSV an export from AS400?</p>
      <input v-if="post_type !== 'none'" v-model="as400" :disabled="busy" @change="isReady"
             type="checkbox"/>
    </div>

    <div v-if="merge !== 'none'" class="pa-importer-file-container">
      <input id="upload" name="import" size="25" type="file" @change="handleFileUpload( $event )">
      <input name="max_file_size" type="hidden" value="8388608">
      <small>Maximum size: 8 MB</small>
    </div>

    <div v-if="ready" class="pa-importer-panel mt-2">
      <button type="button" :disabled="busy" class="button button-primary" :class={button__loading:showLoader} @click="runImport">
          <span class="__button_text" :class={loading:showLoader}>
             Import
          </span>
      </button>
    </div>

    <div class="pa-importer-logs">
      <div class="error-container" v-if="errors.length > 0">
        <ul class="error-log">
          <li v-for="(log, index) in errors" :key="index" class="log-item">{{ log }}</li>
        </ul>
      </div>
      <div class="message-container" v-if="messages.length > 0">
        <ul class="message-log">
          <li v-for="(log, index) in messages" :key="index" class="log-item">{{ log }}</li>
        </ul>
      </div>
    </div>

  </div>

</template>

<script>

import axios from "axios";

/**
 * TODO: Adding to supplement the import process
 *       Adding a dropdown option for:
 *       Simple / Variable
 *       Bundles
 *
 */
export default {
  mounted() {},
  props: {
    taxonomies: {
      type: Array,
      default: [],
    },
    post_types: {
      type: Array,
      default: [],
    },
    busy: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: 'none',
    },
  },
  data() {
    return {
      states: {
        'none': 'No importer',
        'posts': 'Posts',
        'products': 'Products',
        'terms': 'Terms',
      },
      data_type: 'none',
      records_limit: 0,
      records_offset: 0,
      as400: false,
      file: null,
      merge: 'none',
      mergeKey: 'none',
      post_type: 'none',
      export_value: 'none',
      errors: [],
      messages: [],
      have_response: false,
      showProgressBar: true,
      product_option: 'none',
      showOptions: false,
      showLoader: false,
      ready: false,
    }
  },
  methods: {
    onChange(e) {
      const option = e.currentTarget[e.currentTarget.selectedIndex];
      this.data_type = option.dataset.type;
      this.export_value = option.value;
      this.isReady();
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      this.isReady();
    },
    isReady() {
      this.ready = !(
          this.file === null ||
          this.data_type === 'none' ||
          this.export_value === 'none' ||
          (this.merge === 'merge' && this.mergeKey === 'none') ||
          this.merge === 'none' ||
          this.post_type === 'none'
      );
    },
    async runImport(e) {

      let formData = new FormData();
      this.isReady();

      if (this.merge === 'merge' && this.mergeKey === 'none') {
        this.errors.push('Please select a merge key');
        return;
      }

      formData.append('file', this.file);
      formData.append('merge', this.merge);

      if (this.merge === 'merge') {
        formData.append('mergeKey', this.mergeKey);
      }

      formData.append('data_type', this.data_type);
      formData.append('value', this.export_value);
      formData.append('limit', this.records_limit);
      formData.append('offset', this.records_offset);
      formData.append('as400', this.as400);

      try {
        this.errors = [];
        this.messages = [];
        this.have_response = false;
        this.showProgressBar = true;

        const url = window.pAImporterData.restAPIs.import;

        this.showLoader = true;

        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });

        console.log(response);

        // Console log
        console.log(response.data);

        // Display errors
        if (!response.data.success) {
          if(response.data.errors.length > 0) {
            response.data.errors.forEach((err) => {
              this.errors.push(err);
            });
          }
        }

        // Display returned messages
        if(response.data.messages.length > 0) {
          response.data.messages.forEach((msg) => {
            this.messages.push(msg);
          });
        }

        this.showLoader = false;
        this.have_response = true;

      } catch (err) {
        console.log(err);
      }
    },
  },
}
</script>

